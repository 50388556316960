@import '../../assets/styles/00-abstracts/abstracts';@import '../../assets/styles/01-mixins/mixins';.arjo-o-b2c-extra-conttent {
  position: relative;
  padding: 81px 0 86px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background-color: $wave-50;

  @media (min-width: 1024px) {
    height: 100%;
    padding: 31px 0 max(36px, calc(30vh - 400px));
  }

  .bg {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;
  }

  .filter {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1628px;
    height: 1628px;
    background: radial-gradient(48.78% 48.78% at 50% 50%, $white-p 0%, rgba(255, 255, 255, 0) 100%);
    transform: translate(-50%, -40%);
    z-index: 2;
    pointer-events: none;
  }

  .image {
    position: relative;
    max-width: 100%;
    margin: 0 auto;
    z-index: 4;
  }

  .content {
    margin: 0 auto;
    max-width: min(calc(100% - 25vw), 600px);
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    z-index: 4;

    @media (min-width: 1024px) {
      max-width: min(85%, 650px);
    }

    .title {
      margin-top: 60px;
      font-family: $font-santral;
      font-weight: $bold;
      font-size: max(min(5vw, 35px), 25px);
      line-height: 1.28;
    }
  }

  .text {
    margin-top: 16px;
    font-family: $font-santral;
    font-weight: $semi-bold;
    font-size: max(min(4vw, 20px), 16px);
    line-height: 1.5;
  }

  .ctas {
    margin-top: 40px;
  }
}
